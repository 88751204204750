<i18n src="@/locales/swiss_dental_center.json" />
<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-home-content-clinic.json" />

<template>
  <div class="dental-implant">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <div v-html="$t('article_p1')" />
      </ImanArticle>
    </section>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{
            $t('article_p2')
          }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          {{
            $t('article_p3')
          }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_3') }}
        </h3>
        <p>
          {{
            $t('article_p4')
          }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div
          v-for="cardContent in cardContentClinic"
          :key="cardContent.id"
          class="column is-2"
        >
          <ImanCountryCard
            :content="cardContent"
            :country-card-class="countryOverlayClass"
          />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import ImanCountryCard from "@/components/ImanCountryCard";
  import {cardHomeContentClinicMixin} from "@/mixins/card-home-content-clinic-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'SwissDentalCenter',
    components: {
      ImanMoreInfo,
      ImanArticleSection,
      ImanCountryCard
    },
    mixins: [
      articlesCardContentFilterMixin,
      cardHomeContentClinicMixin,
      metaMixin
    ],
    data() {
      return {
        articlesCardContentId: [214, 62, 63, 66],
        mainBackgroundClass: 'none-background',
        countryOverlayClass: {
          overlay:'overlay show-overlay',
          element: 'element show-element'
        },
        img: [
          {
            path: 'clinic/swiss/prof-ihde-anveli-dental.jpg',
            position: 'right'
          },
          {
            path: 'clinic/swiss/soins-dentaire-suisse.jpg',
            position: 'right'
          }
        ],
        video: {
          src: 'https://www.youtube.com/embed/rOjEBtO5Mbo'
        }
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_4.1.png')
      },
      cardContentClinic () {
        return this.cardHomeContentClinic.filter((cardClinic) => cardClinic.id !== 4)
      },
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
          this.countryOverlayClass = {
            overlay:'overlay',
            element: 'element'
          }
        }
        if (document.body.scrollTop > 2000 || document.documentElement.scrollTop > 2000) {
          this.mainBackgroundClass = 'fixed-background'
          this.countryOverlayClass = {
            overlay:'overlay',
            element: 'element'
          }
        }
        if (document.body.scrollTop > 2050 || document.documentElement.scrollTop > 2050) {
          this.countryOverlayClass = {
            overlay:'overlay show-overlay',
            element: 'element show-element'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/clinic/swiss/H1-clinic-swiss-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/clinic/swiss/H1-clinic-swiss-d.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      /* smartphone en mode portait */
      background-image: url("../../../assets/img/clinic/swiss/H1-clinic-swiss-m.jpg");
    }
  }
</style>
